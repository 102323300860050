<template>
  <div class="tutorial-screen-inner align-items-center">
    <div class="tutorial-screen-left">
      <img src="/imgs/ergebnisse-step1.png" alt="Ergebnisse Tutorial" />
    </div>
    <div class="tutorial-screen-right">
      <h3 class="sub-title">{{ info.subTitle }}</h3>
      <h3 class="screen-title">{{ info.title }}</h3>
      <p class="desc mb">
        Anzeige der Ergebnisse erfolgt erst, wenn sie vollständig verfügbar
        sind.
      </p>
      <div class="desc">
        <strong>Produkte gefunden – </strong> Anzahl der in der importierten CSV
        Datei gefundenen
      </div>
      <div class="desc">
        <strong>Auto-Mapping - </strong> Anzahl der Mapping Ergebnisse mit einer
        Wahrscheinlichkeit von mind. 75%
      </div>
      <div class="desc">
        <strong>Manuelles Mapping - </strong> Anzahl aller Mapping Ergebnisse,
        für die ein manuelles Mapping möglich ist
      </div>
      <div class="desc">
        <strong>Mapping erfolgreich -</strong> Anzahl aller Mapping Ergebnisse,
        bei denen nur EAN oder VAN eindeutig ersetzt werden konnte
      </div>
      <div class="desc">
        <strong>Produkte gefunden =</strong> Mapping erfolgreich + Manuelles
        Mapping (inkl. Auto-Mapping als Teilmenge)
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
        subTitle: "ERGEBNISSE",
        title: "Anzeige der Mapping Ergebnisse",
        items: [
          {
            descs: [
              "Trennzeichen: Komma, Semikolon, Tabulator",
              "Header: Spaltentitel",
              "Pflichtspalten: EAN, VAN, Interne Mapping ID des Gesellschafters",
              "! Je mehr Felder Sie angeben, desto genauer wird das vorgeschlagene Ergebnis für die manuelle Zuordnung später."
            ]
          }
        ]
      }
    };
  }
};
</script>

<style lang="scss">
.tutorial-screen {
  &-inner {
    padding-right: 40px;
    padding-left: 65px;
    display: flex;
  }
  &-left {
    min-width: 420px;
    img {
      width: 100%;
    }
  }
  &-right {
    margin-left: 65px;
    .screen-title {
      font-size: 30px;
      line-height: 36px;
      text-transform: uppercase;
      margin-bottom: 32px;
      color: #555555;
    }
    .sub-title {
      font-size: 18px;
      line-height: 22px;
      color: #ee7f00;
    }
    .desc {
      font-size: 14px;
      font-family: Barlow;
      margin-bottom: 10px;
      strong {
        font-family: Barlow;
        font-weight: bold;
      }
      &.mb {
        margin-bottom: 25px;
      }
    }
  }
}
</style>